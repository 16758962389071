import React, { useEffect, useState } from 'react';
import { Button, Select, MenuItem, Tab, Tabs } from '@material-ui/core';
import styles from '../user-list/user-list.module.css';
import { Route, Redirect } from 'react-router-dom';
import { UserListContainer } from '../user-list/user-list.container';

export function UserManagementContainer({ match, history, location }) {

    const [activeTab, setActiveTab] = useState('agents');

    const handleTabChange = (event, value) => {
        setActiveTab(value);
        history.push(`${match.url}/${value}`);
    };

    useEffect(() => {
        const splitedUrl = location.pathname.split('/');
        setActiveTab(splitedUrl[splitedUrl.length - 1]);
    }, [location.pathname]);


    return (
        <div className={styles.UserListContainer}>

            <Tabs
                value={activeTab}
                indicatorColor='primary'
                textColor='primary'
                onChange={handleTabChange}>
                <Tab label='Agents' value={'agents'}/>
                <Tab label='Staff' value={'staff'}/>
            </Tabs>
            <Route exact path={`${match.path}/staff`} component={UserListContainer}/>
            <Route exact path={`${match.path}/agents`} component={UserListContainer}/>
            <Route exact path={`${match.path}`} render={() => (<Redirect to={`${match.path}/staff`}/>)}/>
        </div>);
}
