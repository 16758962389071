import React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { Roles } from '../../constants/roles';
import styles from '../user-list/user-list.module.css';
import { httpService } from '../../utils/api';
import { LocationStatus } from '../../constants/location-status';

export const getTableConfig = (userType) => {
    switch (userType) {
        case 'staff':
            return {
                columns: getColumns(['email', 'role', 'active']),
                data: (query) => {
                    const f = {
                        filters: query.filters.reduce((acc, current) => {
                            if (current.column.field === 'active') {
                                current.value = current.value === 'active' ? true : false;
                            }
                            return {
                                ...acc,
                                [current.column.field]: current.value
                            };
                        }, {}),
                        orderDirection: query.orderDirection,
                        page: query.page,
                        pageSize: query.pageSize,
                        search: query.search,
                        orderBy: query && query.orderBy && query.orderBy.field
                    };

                    return httpService.get(`staff?filters=${JSON.stringify(f)}`,).then((response) => {
                        return new Promise((resolve, reject) => {
                            resolve(
                                {
                                    data: response.data.result.staffList,
                                    totalCount: +response.data.result.totalCount,
                                    page: response.data.result.originalFilters.page
                                });
                        });
                    });
                },
                actions: [
                    function (rowData) {
                        return {
                            materialTableRef: this,
                            icon: rowData.active ? 'clear' : 'check',
                            tooltip: rowData.active ? 'Deactivate User' : 'Activate User',
                            onClick: function (event, rowData) {
                                const materialTable = this.materialTableRef.current;
                                httpService.patch(
                                    `staff/${rowData.userId}`,
                                    { active: !rowData.active }
                                ).then(response => {
                                    rowData.active = !rowData.active;
                                    materialTable.forceUpdate();
                                }).catch(err => {
                                    //    TODO toast
                                });

                            },
                        };
                    }
                ],
                onRowClickHandler: (event, data, history) => {
                    history.push(`/user/${data.userId}`);
                }
            };
        case 'agents':
            return {
                columns: getColumns([
                    'email',
                    'name',
                    'family',
                    'phone',
                    'iban',
                    'openLocations',
                    'payedLocations',
                    'openBallance',
                    'payedBallance',
                    'active'
                ]),
                data: (query) => {
                    const f = {
                        filters: query.filters.reduce((acc, current) => {
                            if (current.column.field === 'active') {
                                current.value = current.value === 'active' ? true : false;
                            }
                            return {
                                ...acc,
                                [current.column.field]: current.value
                            };
                        }, {}),
                        orderDirection: query.orderDirection,
                        page: query.page,
                        pageSize: query.pageSize,
                        search: query.search,
                        orderBy: query && query.orderBy && query.orderBy.field,
                        status: ''
                    };
                    return httpService.get(`agent?filters=${JSON.stringify(f)}`,).then((response) => {
                        return new Promise((resolve, reject) => {
                            resolve(
                                {
                                    data: response.data.result.agentList,
                                    totalCount: +response.data.result.totalCount,
                                    page: response.data.result.originalFilters.page
                                });
                        });
                    });
                },
                actions: [
                    function (rowData) {
                        return {
                            materialTableRef: this,
                            icon: rowData.active ? 'clear' : 'check',
                            tooltip: rowData.active ? 'Deactivate User' : 'Activate User',
                            onClick: function (event, rowData) {
                                const materialTable = this.materialTableRef.current;
                                httpService.put(
                                    `agent/${rowData.userId}`,
                                    {
                                        ...rowData,
                                        active: !rowData.active
                                    }
                                ).then(response => {
                                    rowData.active = !rowData.active;
                                    materialTable.forceUpdate();
                                }).catch(err => {
                                    //    TODO toast
                                });

                            },
                        };
                    }
                ],
                onRowClickHandler: (event, data, history) => {
                    history.push(`/agents/${data.userId}`);
                }
            };
        case 'exported':
            return {
                bah: {
                    // PENDING, REJECTED, APPROVED,
                    'viewOnMap': '',
                    'category': 'fashion',
                    'name': '',
                    'responsibleParty': 'Valio Dimitrov',
                    'bulstat': 'BG2013876544234',
                    'phone': '0899543345',
                    'status': 'PENDING',
                    'price': '0.50',
                    'updatedOn': '2020-08-30 18:07:45',
                    'ownerEmail': 'lyubo.slavilov@gmail.com',
                },
                columns: getColumns(['category', 'name', 'responsibleParty', 'bulstat', 'phone', 'status', 'price', 'updatedOn', 'ownerEmail']),
                onRowClickHandler: (event, data, history) => {
                    history.push(`/location/${data.id}`);
                },
                actions: [
                    function (rowData) {
                        return {
                            materialTableRef: this,
                            icon: 'map',
                            tooltip: 'Show on map',
                            onClick: function (event, rowData) {

                                window.open(`https://www.google.com/maps/place/${rowData.lat},${rowData.lng}`)
                            },
                        };
                    }
                ],
                data: (query) => {
                    const f = {
                        filters: query.filters.reduce((acc, current) => {
                            if (current.column.field === 'active') {
                                current.value = current.value === 'active' ? true : false;
                            }
                            return {
                                ...acc,
                                [current.column.field]: current.value
                            };
                        }, {}),
                        orderDirection: query.orderDirection,
                        page: query.page,
                        pageSize: query.pageSize,
                        search: query.search,
                        orderBy: query && query.orderBy && query.orderBy.field
                    };

                    return httpService.get(`location?filters=${JSON.stringify(f)}`,).then((response) => {
                        return new Promise((resolve, reject) => {
                            resolve(
                                {
                                    data: response.data.result.locationList,
                                    totalCount: +response.data.result.totalCount,
                                    page: response.data.result.originalFilters.page
                                });
                        });
                    });
                },
            };
        case 'not-exported':
            return {
                bah: {
                    // PENDING, REJECTED, APPROVED,
                    'viewOnMap': '',
                    'category': 'fashion',
                    'name': '',
                    'responsibleParty': 'Valio Dimitrov',
                    'bulstat': 'BG2013876544234',
                    'phone': '0899543345',
                    'status': 'PENDING',
                    'price': '0.50',
                    'updatedOn': '2020-08-30 18:07:45',
                    'ownerEmail': 'lyubo.slavilov@gmail.com',
                },
                columns: getColumns(['category', 'name', 'responsibleParty', 'bulstat', 'phone', 'status', 'price', 'updatedOn', 'ownerEmail']),
                onRowClickHandler: (event, data, history) => {
                    history.push(`/location/${data.id}`);
                },
                actions: [
                    function (rowData) {
                        return {
                            materialTableRef: this,
                            icon: 'map',
                            tooltip: 'Show on map',
                            onClick: function (event, rowData) {

                                window.open(`https://www.google.com/maps/place/${rowData.lat},${rowData.lng}`)
                            },
                        };
                    }
                ],
                data: (query) => {
                    const f = {
                        filters: query.filters.reduce((acc, current) => {
                            if (current.column.field === 'active') {
                                current.value = current.value === 'active' ? true : false;
                            }
                            return {
                                ...acc,
                                [current.column.field]: current.value
                            };
                        }, {}),
                        orderDirection: query.orderDirection,
                        page: query.page,
                        pageSize: query.pageSize,
                        search: query.search,
                        orderBy: query && query.orderBy && query.orderBy.field
                    };

                    return httpService.get(`location?filters=${JSON.stringify(f)}`,).then((response) => {
                        return new Promise((resolve, reject) => {
                            resolve(
                                {
                                    data: response.data.result.locationList,
                                    totalCount: +response.data.result.totalCount,
                                    page: response.data.result.originalFilters.page
                                });
                        });
                    });
                },
            };

    }
};

const roleFilterOptions = Object.keys(Roles).map(role => {
    return (<MenuItem key={Roles[role]} value={Roles[role]}>{Roles[role]}</MenuItem>);
});

const locationStatusOptions = Object.keys(LocationStatus).map(status => {
    return (<MenuItem key={LocationStatus[status]} value={LocationStatus[status]}>{LocationStatus[status]}</MenuItem>);
});

const columnPool = [
    {
        title: 'Email',
        field: 'email', // field is the "key" in the data,
        filtering: false
    },
    {
        title: 'Price',
        field: 'price', // field is the "key" in the data,
        filtering: false
    },
    {
        title: 'Updated on',
        field: 'updatedOn', // field is the "key" in the data,
        filtering: false
    },
    {
        title: 'Status',
        field: 'status', // field is the "key" in the data,
        filterComponent: (props) => {
            return (
                <Select
                    className={styles.FilterItem}
                    onChange={(event) => props.onFilterChanged(props.columnDef.tableData.id, event.target.value)}
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'>
                    <MenuItem value={undefined}>None</MenuItem>
                    {locationStatusOptions}
                </Select>
            );
        }
    },
    {
        title: 'Responsible party',
        field: 'responsibleParty', // field is the "key" in the data,
        filtering: false
    },
    {
        title: 'Owner email',
        field: 'ownerEmail', // field is the "key" in the data,
        filtering: false
    },
    {
        title: 'Bulstat',
        field: 'bulstat', // field is the "key" in the data,
        filtering: false
    },
    {
        title: 'Category',
        field: 'category', // field is the "key" in the data,
        filtering: false
    },
    {
        title: 'Name',
        field: 'name',
        filtering: false
    },
    {
        title: 'Family name',
        field: 'family',
        filtering: false
    },
    {
        title: 'Phone',
        field: 'phone',
        filtering: false
    },
    {
        title: 'Iban',
        field: 'iban',
        filtering: false
    },
    {
        title: 'Open locations',
        field: 'openLocations',
        filtering: false
    },
    {
        title: 'Payed locations',
        field: 'payedLocations',
        filtering: false
    },
    {
        title: 'Open balance',
        field: 'openBallance',
        filtering: false
    },
    {
        title: 'Payed balance',
        field: 'payedBallance',
        filtering: false
    },
    {
        title: 'Role',
        field: 'role',
        filterComponent: (props) => {
            return (
                <Select
                    className={styles.FilterItem}
                    onChange={(event) => props.onFilterChanged(props.columnDef.tableData.id, event.target.value)}
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'>
                    <MenuItem value={undefined}>None</MenuItem>
                    {roleFilterOptions}
                </Select>
            );
        }
    },
    {
        title: 'Status',
        field: 'active',
        render: (data) => data.active ? 'Active' : 'Inactive',
        filterComponent: (props) => {
            return (
                <Select
                    className={styles.FilterItem}
                    onChange={(event) => {
                        props.onFilterChanged(props.columnDef.tableData.id, event.target.value);
                    }}
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'>
                    <MenuItem value={undefined}>None</MenuItem>
                    <MenuItem value={'active'}>Active</MenuItem>
                    <MenuItem value={'inactive'}>Inactive</MenuItem>
                </Select>
            );
        }
    }
];

const getColumns = (columnTypeList) => {
    return columnTypeList.map(colType => ({ ...columnPool.find(column => column.field === colType) }));
};

