import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Routes } from '../../constants/routes';

export const ProtectedRoute = ({
                                   component: Component,
                                   ...rest
                               }) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (rest.loggedUser && rest.loggedUser.id) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: Routes.LOGIN.path,
                            }}
                        />
                    );
                }
            }}
        />
    );
};
