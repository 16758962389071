import React, { useEffect, useState } from 'react';
import { Routes } from './constants/routes.js';
import './App.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CreateUserContainer } from './views/create-user/create-user.container';
import { UserListContainer } from './views/user-list/user-list.container';
import { UserManagementContainer } from './views/user-management/user-management.container';
import { HeaderComponent } from './components/header/header.component';
import { httpService } from './utils/api';
import { Snackbar, Button } from '@material-ui/core';
import { LoginContainer } from './views/login/login.container';
import { ProtectedRoute } from './components/protected-route/protected-route.component';
import { withRouter } from 'react-router-dom';
import { userSelector } from './store/selectors/user.selector';
import { useSelector, useDispatch } from 'react-redux';
import { UserActions } from './store/actions/user.actions';
import JSONPretty from 'react-json-pretty';
import { JSON_ERROR_ACTIONS } from './store/actions/json-error.actions';
import { EditAgent } from './views/edit-agent/edit-agent.component';
import { LocationsManagement } from './views/location-list/locations-management.container';
import { EditLocation } from './views/location-edit/location-edit.component';

export const ToastContext = React.createContext({
    message: '',
    open: true
});

function App(props) {
    const [initializationDone, setInitializationDone] = useState(false);
    const user = useSelector(userSelector);
    const dispatch = useDispatch();
    const jsonError = useSelector(state => state.jsonError);

    const [toastState, setToastState] = useState({
        open: false,
        message: ''
    });

    const addToast = (message) => {
        setToastState({ open: true, message });
    };

    const closeToast = () => {
        setToastState({ open: false, message: '' });
    };

    useEffect(() => {
        httpService.get('ping').then(response => {
            dispatch({ type: UserActions.SAVE_USER, payload: response.data.currentUser });
            setInitializationDone(true);
        }).catch(() => {
            setInitializationDone(true);
            redirectToLogin();
        });
    }, []);

    const onLogout = () => {
        httpService.get('auth/logout').then(() => {
            dispatch({ type: UserActions.RESET });
            localStorage.removeItem('token');
            redirectToLogin();
        });
    };

    const redirectToLogin = () => {
        props.history.push(Routes.LOGIN.path);
    };

    const resetJsonError = () => {
        dispatch({ type: JSON_ERROR_ACTIONS.UPDATE_ERROR, payload: null });
    };

    return (
        <div>
            {initializationDone ? <ToastContext.Provider value={{ ...toastState, addToast, closeToast }}>
                {user && user.id ? <HeaderComponent user={user} onLogout={onLogout}/> : null}
                <Switch>
                    <ProtectedRoute loggedUser={user} exact path='/'
                                    render={() => (<Redirect to={Routes.DEFAULT.path}/>)}/>
                    <ProtectedRoute loggedUser={user} exact path={Routes.CREATE_USER.path}
                                    component={CreateUserContainer}/>
                    <Route exact path={Routes.LOGIN.path} component={LoginContainer}/>
                    <ProtectedRoute loggedUser={user} path={Routes.USER_LIST.path} component={UserManagementContainer}/>
                    <ProtectedRoute loggedUser={user} path={Routes.LOCATION_LIST.path} component={LocationsManagement}/>
                    <ProtectedRoute loggedUser={user} exact path={Routes.USER_EDIT.path} component={CreateUserContainer}/>
                    <ProtectedRoute loggedUser={user} exact path={'/agents/:id'} component={EditAgent}/>
                    <ProtectedRoute loggedUser={user} exact path={'/location/:id'} component={EditLocation}/>
                </Switch>
                <Snackbar
                    open={toastState.open}
                    autoHideDuration={2000}
                    message={toastState.message}
                    action={
                        <Button onClick={() => setToastState({ message: '', open: false })} color='inherit'
                                size='small'>
                            X
                        </Button>
                    }
                />
            </ToastContext.Provider> : null}

            {jsonError ? <div className='json-error-placehoder'>
                <span className='material-icons' onClick={resetJsonError}>close</span>
                <JSONPretty id='json-pretty' data={jsonError}></JSONPretty>
            </div> : <div></div>}
        </div>
    );
}

export default withRouter(App);
