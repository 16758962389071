export const Routes = {
    CREATE_USER: {
        path: '/create-user'
    },
    DEFAULT: {
        path: '/user-list'
    },
    USER_LIST: {
        path: '/user-list'
    },
    USER_EDIT: {
        path: '/user/:id'
    },
    LOGIN: {
        path: '/login'
    },
    LOCATION_LIST: {
        path: "/locations",
    },
};