import React, { useEffect, useState } from 'react';
import { httpService } from '../../utils/api';
import { InputLabel, Card, TextField, Button } from '@material-ui/core';
import { ToastContext } from '../../App';
import { JSON_ERROR_ACTIONS } from '../../store/actions/json-error.actions';
import { useDispatch } from 'react-redux';

export function EditAgent({ match, history, location }) {

    const context = React.useContext(ToastContext);
    const dispatch = useDispatch();
    const [agent, setAgent] = useState(null);
    const [passwordError, setPasswordError] = useState('');

    useEffect(() => {
        httpService.get(`agent/${match.params.id}`).then(response => {
            setAgent(response.data.result.agent);
        }).catch(err => {
            dispatch({ type: JSON_ERROR_ACTIONS.UPDATE_ERROR, payload: err.response.data.details });
        });
    }, []);

    const submit = () => {

        if (agent.password && agent.password !== agent.confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }

        httpService.put(`agent/${match.params.id}`, agent).then(response => {
            context.addToast('Successfully edited user');
        }).catch(err => {
            context.addToast('Could not edit user');
            dispatch({ type: JSON_ERROR_ACTIONS.UPDATE_ERROR, payload: err.response.data.details });
        });
    };

    return agent ?
        <div className='edit-agent-container'>
            <Card className='agent-card-item'>
                <div className='agent-info-item'>
                    <InputLabel className='label'>Open locations: </InputLabel>
                    <span>{agent.open_locations}</span>
                </div>
                <div className='agent-info-item'>
                    <InputLabel className='label'>Payed locations: </InputLabel>
                    <span>{agent.payed_locations}</span>
                </div>
                <div className='agent-info-item'>
                    <InputLabel className='label'>Open balance: </InputLabel>
                    <span>{agent.open_ballance}</span>
                </div>
                <div className='agent-info-item'>
                    <InputLabel className='label'>Payed balance:</InputLabel>
                    <span>{agent.payed_ballance}</span>
                </div>
                <div className='agent-info-item'>
                    <TextField
                        type='text'
                        label='Name'
                        value={agent.name}
                        onChange={(e) => setAgent({ ...agent, name: e.target.value })}/>
                </div>
                <div className='agent-info-item'>
                    <TextField
                        type='text'
                        label='family'
                        value={agent.family}
                        onChange={(e) => setAgent({ ...agent, family: e.target.value })}/>
                </div>
                <div className='agent-info-item'>
                    <TextField
                        type='text'
                        label='phone'
                        value={agent.phone}
                        onChange={(e) => setAgent({ ...agent, phone: e.target.value })}/>
                </div>
                <div className='agent-info-item'>
                    <TextField
                        type='text'
                        label='iban'
                        value={agent.iban}
                        onChange={(e) => setAgent({ ...agent, iban: e.target.value })}/>
                </div>
                <Button variant='contained' color='primary' className='submit-button' onClick={submit}>Edit</Button>
            </Card>
            <Card className='agent-card-item'>
                <div className='agent-info-item'>
                    <TextField
                        type='password'
                        label='Password'
                        onChange={(e) => {
                            setPasswordError('');
                            setAgent({ ...agent, password: e.target.value })}
                        }/>
                </div>
                <div className='agent-info-item'>
                    <TextField
                        type='password'
                        label='Confirm password'
                        onChange={(e) => {
                            setPasswordError('');
                            setAgent({ ...agent, confirmPassword: e.target.value })}
                        }/>
                    <div className='password-mismatch'>{passwordError}</div>
                </div>
                <Button
                    variant='contained'
                    color='primary'
                    className='submit-button'
                    onClick={submit}>
                    Change
                </Button>
            </Card>
        </div> : (<div></div>);

}
