import React, { useEffect, useState } from 'react';
import { httpService } from '../../utils/api';
import { JSON_ERROR_ACTIONS } from '../../store/actions/json-error.actions';
import { useDispatch } from 'react-redux';
import { InputLabel, Card, TextField, Button, TextareaAutosize, MenuItem, Select } from '@material-ui/core';
import './location-edit.component.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Carousel } from 'react-responsive-carousel';

import { apiUrl } from '../../utils/api';
import { ToastContext } from '../../App';


export function EditLocation({ match, history, location }) {
    const dispatch = useDispatch();
    const context = React.useContext(ToastContext);
    const [place, setPlace] = useState(null);
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    useEffect(() => {
        httpService.get(`location/${match.params.id}`).then(response => {
            setPlace(response.data.result.location);
        }).catch(err => {
            dispatch({ type: JSON_ERROR_ACTIONS.UPDATE_ERROR, payload: err.response.data.details });
        });
    }, [match.params.id]);

    const submit = () => {

        if (place.status !== 'REJECTED' ||
            (place.status === 'REJECTED' &&
                window.confirm('If you reject the location you will not be able to change this later'
                ))) {
            httpService.put(`location/${match.params.id}`, place).then(response => {
                context.addToast('Successfully edit the location');
            }).catch(err => {
                context.addToast('Could not edit location');
                dispatch({ type: JSON_ERROR_ACTIONS.UPDATE_ERROR, payload: err.response.data.details });
            });

        }
    };

    const attachments = place ? place.attachments.map((att, index) => {
        return (
            <li>
                <img key={`att${index}`} src={`${apiUrl}${place.attachmentURI.replace(':path', att.path)}`}/>
            </li>);
    }) : null;

    return place ?
        <div className='edit-agent-container location-edit'>
            <Card className='agent-card-item'>
                <div className='agent-info-item'>
                    <InputLabel className='label'>Added on: </InputLabel>
                    <span>{place.addedOn}</span>
                </div>
                <div className='agent-info-item'>
                    <InputLabel className='label'>Updated on:</InputLabel>
                    <span>{place.updatedOn}</span>
                </div>
                <div className='agent-info-item'>
                    <InputLabel className='label'>Price:</InputLabel>
                    <span>{place.price}</span>
                </div>
                <div className='agent-info-item'>
                    <InputLabel className='label'>Owner email:</InputLabel>
                    <span>{place.ownerEmail}</span>
                </div>
                <div className='agent-info-item'>
                    <InputLabel className='label'>Owner full name</InputLabel>
                    <span>{place.ownerName} {place.ownerFamily}</span>
                </div>
                <div className='agent-info-item'>
                    <InputLabel className='label'>Status: </InputLabel>
                    <Select
                        onChange={(e) => setPlace({ ...place, status: e.target.value })}
                        labelId='demo-simple-select-label'
                        value={place.status}
                        id='demo-simple-select'>
                        <MenuItem value='PENDING'>PENDING</MenuItem>
                        <MenuItem value='REJECTED'>REJECTED</MenuItem>
                        <MenuItem value='APPROVED'>APPROVED</MenuItem>
                    </Select></div>
                <div className='agent-info-item'>
                    <TextField
                        type='text'
                        label='Name'
                        value={place.name}
                        onChange={(e) => setPlace({ ...place, name: e.target.value })}/>
                </div>
                <div className='agent-info-item'>
                    <TextField
                        type='text'
                        label='Responsible party'
                        value={place.responsibleParty}
                        onChange={(e) => setPlace({ ...place, responsibleParty: e.target.value })}/>
                </div>
                <div className='agent-info-item'>
                    <TextField
                        type='text'
                        label='Bulstat'
                        value={place.bulstat}
                        onChange={(e) => setPlace({ ...place, bulstat: e.target.value })}/>
                </div>
                <div className='agent-info-item'>
                    <TextField
                        type='text'
                        label='Phone'
                        value={place.phone}
                        onChange={(e) => setPlace({ ...place, phone: e.target.value })}/>
                </div>
                <div className='agent-info-item'>
                    <TextField
                        type='text'
                        label='Category'
                        value={place.category}
                        onChange={(e) => setPlace({ ...place, category: e.target.value })}/>
                </div>
                <div className='agent-info-item'>
                    <TextareaAutosize
                        rowsMin={3}
                        value={place.notes}
                        placeholder='Empty'
                        onChange={(e) => setPlace({ ...place, notes: e.target.value })}/>
                </div>
                <Button variant='contained' color='primary' className='submit-button' onClick={submit}>Edit</Button>
            </Card>
            <ul title='Open pictures view' onClick={() => setOpen(true)} className='pictures-container'>
                {attachments}
                <li></li>
            </ul>
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
                <div className='modal'>
                    <a className='close' onClick={closeModal}>
                        &times;
                    </a>
                    <Carousel centerMode={true} className='location-edit-carousel' dynamicHeight={true}>
                        {attachments}
                    </Carousel>
                </div>
            </Popup>
        </div> : (<div></div>);
    return (<div></div>);

}
