import React, { useEffect, useState } from 'react';
import { ToastContext } from '../../App';
import { TextField, Button, FormControl, InputLabel } from '@material-ui/core';
import { httpService } from '../../utils/api';
import classes from './create-user.module.css';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Roles } from '../../constants/roles';
import { useSelector, useDispatch } from 'react-redux';
import { Routes } from '../../constants/routes';
import { JSON_ERROR_ACTIONS } from '../../store/actions/json-error.actions';

export function CreateUserContainer({ match, history, location }) {
    const context = React.useContext(ToastContext);
    const dispatch = useDispatch();
    const [isEditMode, setEditMode] = useState(false);

    const [formState, setFormState] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        role: '',
        error: '',
    });

    useEffect(() => {
        context.closeToast();
        setEditMode(!!match.params.id);
        setFormState({email: '',
            password: '',
            confirmPassword: '',
            role: '',
            error: '',})
    }, [location]);

    useEffect(() => {
        if (isEditMode) {
            httpService.get(`staff/${match.params.id}`).then(response => {
                setFormState(response.data.result.user);
            }).catch(err => {
                debugger;
            });
        }
    }, [isEditMode]);

    const onSubmit = () => {
        if (formState.password != formState.confirmPassword) {
            setFormState({ ...formState, error: 'Passwords do not match' });
        } else {
            const method = isEditMode ? 'patch' : 'post';
            const url = isEditMode ? `staff/${match.params.id}` : 'staff';
            httpService
                [method](url, formState)
                .then((response) => {
                    dispatch({ type: JSON_ERROR_ACTIONS.UPDATE_ERROR, payload: null });
                    history.push(Routes.USER_LIST.path);
                    context.addToast(`Successfully ${isEditMode ? 'edited' : 'created'} user`);
                })
                .catch((err) => {
                    dispatch({type: JSON_ERROR_ACTIONS.UPDATE_ERROR, payload: err.response.data.details});
                    context.addToast(err.response.data.message);
                });
        }
    };

    const handleChange = (event) => {
        setFormState({ ...formState, role: event.target.value });
    };


    const roleFilterOptions = Object.keys(Roles).map(role => {
        return (<MenuItem key={Roles[role]} value={Roles[role]}>{Roles[role]}</MenuItem>);
    });

    return (
        <div className={classes.FormContainerWrapper}>
            <div className={classes.FormContainer}>

                <div className={classes.FormTitle}>{isEditMode ? 'Edit User' : 'Create New User'}</div>
                <form className={classes.Form}>
                    <div>
                        <TextField
                            disabled={isEditMode}
                            className={classes.TextField}
                            type='email'
                            label='Email'
                            onChange={(e) =>
                                setFormState({ ...formState, email: e.target.value })
                            }
                            value={formState.email}
                        />
                    </div>
                    <div>
                        <TextField
                            className={classes.TextField}
                            type='password'
                            label='Password'
                            onChange={(e) =>
                                setFormState({ ...formState, password: e.target.value })
                            }
                            value={formState.password}
                        />
                    </div>
                    <div>
                        <TextField
                            error={formState.error}
                            className={classes.TextField}
                            type='password'
                            label='Confirm Password'
                            helperText={formState.error ? formState.error : ''}
                            onChange={(e) =>
                                setFormState({
                                    ...formState,
                                    confirmPassword: e.target.value,
                                    error: '',
                                })
                            }
                            value={formState.confirmPassword}
                        />
                    </div>
                    <div>
                        <FormControl
                            required
                            className={classes.TextField + ' ' + classes.SelectField}
                        >
                            <InputLabel id='demo-simple-select-label'>Role</InputLabel>
                            <Select
                                className={classes.SelectField}
                                labelId='select-helper-label'
                                id='select-helper'
                                value={formState.role}
                                onChange={handleChange}>
                                {roleFilterOptions}
                            </Select>
                        </FormControl>
                    </div>
                </form>
                <Button
                    className={classes.FormButton}
                    type='button'
                    onClick={() => onSubmit()}
                >
                    {isEditMode ? 'Edit' : 'Create'}
                </Button>
            </div>
        </div>
    );
}
