import React, { useEffect } from "react";
import { Routes } from "../../constants/routes.js";
import { NavLink } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

export function HeaderComponent({ user, onLogout }) {

  return (
    <header>
      <div className="app-title">BEONTHEMAP ADMIN</div>
      <nav>
        <ul>
          <li className="mega-menu-titAccountCircleIconle">
            <NavLink to={Routes.CREATE_USER.path}>Create new user</NavLink>
          </li>
          <li className="mega-menu-title">
            <NavLink to={`${Routes.USER_LIST.path}`} activeClassName="active">
              User management
            </NavLink>
          </li>
          <li className="mega-menu-title">
            <NavLink to={Routes.LOCATION_LIST.path} activeClassName="active">
              Locations
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className="profile-info">
        <AccountCircleIcon />
        Hi, {user.email}
        <div className="profile-menu-dropdown">
          <ul>
            <li>
              <a onClick={() => onLogout()}>Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
