import { UserActions } from '../actions/user.actions';

export const initialState = {
    id: null,
    email: '',
    role: '',
    activated: false
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserActions.SAVE_USER:
            return {
                ...state,
                ...action.payload
            };
        case UserActions.RESET:
            return {
                ...initialState
            };
    }
    return state;
};
