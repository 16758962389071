import { combineReducers } from 'redux';
import { userReducer } from './user.reducer';
import {staffReducer } from './staff.reducer';
import { jsonErrorReducer } from './json-error.reducer';

export const USER_STATE= 'user';
export const STAFF_LISTING= 'staff';
export const JSON_ERROR = 'jsonError';

export default combineReducers({
    [USER_STATE]: userReducer,
    [STAFF_LISTING]: staffReducer,
    [JSON_ERROR]: jsonErrorReducer
});


