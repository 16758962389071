import { StaffActions } from '../actions/staff.actions';

export const initialState = {
    list: []
};

export const staffReducer = (state = initialState, action) => {
    switch (action.type) {
        case StaffActions.UPDATE_LIST:
            return {
                ...state,
                list: [...action.payload]
            }
    }
    return state;
};
