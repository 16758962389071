import { JSON_ERROR_ACTIONS } from '../actions/json-error.actions';

export const initialState = null;

export const jsonErrorReducer = (state = initialState, action) => {
    switch (action.type) {
        case JSON_ERROR_ACTIONS.UPDATE_ERROR:
            return action.payload;
    }
    return state;
};
