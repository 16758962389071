import React, { useEffect, useState } from 'react';
import { httpService } from '../../utils/api';
import classes from '../create-user/create-user.module.css';
import { TextField, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { UserActions } from '../../store/actions/user.actions';
import { ToastContext } from '../../App';

export function LoginContainer() {
    const dispatch = useDispatch();
    const [formState, setFormState] = useState({ email: 'lyubo.slavilov@gmail.com', password: 'mapinvaders' });
    const context = React.useContext(ToastContext);

    const onSubmit = () => {
        httpService.post('auth/login', formState).then((response) => {
            localStorage.setItem('token', response.data.result.sessionToken);
            dispatch({ type: UserActions.SAVE_USER, payload: response.data.currentUser });
            setFormState({ email: '', password: '' });
        }).catch((error) => {
            context.addToast('Could not login');
        });
    };

    return (
        <div className={classes.FormContainerWrapper}>
            <div className={classes.FormContainer}>
                <div className={classes.FormTitle}>Login</div>
                <form className={classes.Algo}>
                    <div>
                        <TextField
                            className={classes.TextField}
                            type='text'
                            label='Username'
                            onChange={(e) =>
                                setFormState({ ...formState, email: e.target.value })
                            }
                            value={formState.email}
                        />
                    </div>
                    <div className={classes.Algo}>
                        <TextField
                            className={classes.TextField}
                            type='password'
                            label='Password'
                            onChange={(e) =>
                                setFormState({ ...formState, password: e.target.value })
                            }
                            value={formState.password}
                        />
                    </div>
                </form>
                <Button
                    className={classes.FormButton}
                    type='button'
                    onClick={() => onSubmit()}
                >
                    Login
                </Button>
            </div>
        </div>
    );
}
